import { get } from 'lodash';
import { cancel, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { LOGOUT_SUCCESS } from '../login/actions';
import { clearReport, fetchReport, GET_REPORT } from './actions';
import { selectEmbedToken } from './reducer';

export const SAFETY_INTERVAL = 3000000;
const hostname = window.location.hostname;
const isWashClub =
  hostname === 'portal.wash.club' ||
  hostname === 'portal.staging-wash.club' ||
  hostname === 'portal.dev-wash.club';
function getTimeout(token) {
  const tokenExpiration = get(token, 'expiration');

  const currentTime = Date.now();
  const expiration = Date.parse(tokenExpiration);
  const timeout = expiration - currentTime;

  if (Number.isNaN(timeout)) {
    return 0;
  }

  return timeout;
}

/**
 * If the fetched token is immediately expired, we'll refetch the token
 * and provide a safety interval to avoid loops.
 *
 * NOTE:
 * If the expiration of the token is faulty or there's something
 * wrong with the way we calculate the timeout value,
 * we're fetching the report every SAFETY_INTERVAL milliseconds.
 */
function* refetchTokenAfterInterval(reportAction) {
  const providedSafetyInterval = get(reportAction, 'meta.safetyInterval');
  const reportName = get(reportAction, 'meta.options.reportName', '');
  if (providedSafetyInterval) {
    yield delay(providedSafetyInterval);
  }

  yield put(fetchReport(SAFETY_INTERVAL, reportName, isWashClub));
}

/**
 * We'll wait minimum of SAFETY_INTERVAL milliseconds
 * before fetching the report. Normally the timeout should
 * higher than that, so we're actually waiting for the duration
 * of the timeout.
 */
function* refetchTokenAfterTimeout(timeout, reportAction) {
  const msBeforeTimeout = 2000;
  const reportName = get(reportAction, 'meta.options.reportName', '');

  if (timeout < SAFETY_INTERVAL) {
    yield delay(SAFETY_INTERVAL);
  } else {
    yield delay(timeout - msBeforeTimeout);
  }

  yield put(fetchReport(SAFETY_INTERVAL, reportName, isWashClub));
}

export function* tokenHandler(reportAction) {
  const newToken = yield select(selectEmbedToken);
  const timeout = getTimeout(newToken);

  if (timeout <= 0) {
    yield refetchTokenAfterInterval(reportAction);
  } else {
    yield refetchTokenAfterTimeout(timeout, reportAction);
  }
}

export function* refreshEmbedToken(reportAction) {
  const trackToken = yield fork(tokenHandler, reportAction);
  const shouldCancelTracking = yield take(LOGOUT_SUCCESS);

  if (shouldCancelTracking) {
    yield cancel(trackToken);
    yield put(clearReport());
  }
}

export function* watchEmbedTokenExpiration() {
  yield takeLatest(`${GET_REPORT}_SUCCESS`, refreshEmbedToken);
}
