import { find, result } from 'lodash';
import { UserReportViewType } from './models';

const initialState = {
  reports: {},
  dashboard: {},
};

const handleGetReportSuccess = (state, action) => {
  const { id, embedToken, embedUrl, pages } = action.response;
  const key = action?.meta?.options?.reportName;
  const isMulti = pages.find((page) => page.displayName.includes('Multi'));

  return {
    ...state,
    reports: {
      ...state.reports,
      [key]: {
        reportId: id,
        embedToken: embedToken.token,
        embedUrl,
        pages,
        permission: isMulti ? UserReportViewType.Multi : UserReportViewType.Single,
        redirectedPage: pages[0],
      },
    },
  };
};

const handleGetDashboardTilesSuccess = (state, action) => {
  const { id, datasetId, embedUrl, embedToken, accessToken } = action.response;
  const key = action?.meta?.options?.specificReport;

  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      [key]: {
        name: key,
        id,
        embedToken: embedToken.token,
        accessToken,
        embedUrl,
        datasetId,
      },
    },
  };
};

export const azure = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REPORT_SUCCESS':
      return handleGetReportSuccess(state, action);
    case 'SET_PERMISSION':
      return {
        ...state,
        permission: action.payload,
      };
    case 'SET_ANALYTICS_PAGE':
      return {
        ...state,
        redirectedPage: action.payload,
      };
    case 'CLEAR_REPORT':
      return initialState;
    case 'GET_HOURLY_COUNTS_REPORT_SUCCESS':
      return handleGetDashboardTilesSuccess(state, action);
    default:
      return state;
  }
};

export const selectPages = (state, id) => {
  return state.azure.reports[id]?.pages;
};

export const selectEmbedToken = (state, id) => {
  return state.azure.reports[id]?.embedToken || null;
};

export const selectEmbedUrl = (state, id) => {
  return state.azure.reports[id]?.embedUrl;
};

export const selectReportId = (state, id) => {
  return state.azure.reports[id]?.reportId;
};

export const selectPermission = (state, id) => {
  return state.azure?.reports[id]?.permission;
};

export const selectRedirectedPage = (state, id) => {
  return state.azure?.reports[id]?.redirectedPage;
};

export const selectStartingView = (state, id) => {
  return state.azure?.reports[id]?.redirectedPage?.split('-')[0];
};

export const selectPowerBiConfig = (state, id) => {
  if (!state.azure.reports[id]) {
    return null;
  }
  return {
    embedToken: state.azure.reports[id].embedToken,
    embedUrl: state.azure.reports[id].embedUrl,
    reportId: state.azure.reports[id].reportId,
  };
};

export const selectHourlyWashCountsReport = (state, id) => {
  if (!state.azure.dashboard[id]) {
    return null;
  }
  return state.azure.dashboard[id];
};

export const selectInitialPage = (state, id) => {
  return result(
    find(state.azure?.reports[id]?.pages, (page) =>
      page.displayName.includes(state.azure?.redirectedPage ?? 'Single')
    ),
    'name'
  );
};

export const selectInitialTab = (state) => {
  return state.azure?.redirectedPage?.split('-')[0] ?? 'Single';
};
