import classNames from 'classnames';
import { find, get, result } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fetchSites } from '../../../core/actions';
import Loader from '../../../core/components/Loader';
import { selectSite } from '../../../store/authReducer';
import {
  selectMultiSiteSelectionExternalIds,
  selectSiteName,
  selectSites,
} from '../../site-selection/reducer';
import { fetchReport, setAnalyticsPage, setPermissionLevel, GET_REPORT } from '../actions';
import NavTabs from '../components/NavTabs';
import PermissionViewButtons from '../components/PermissionViewButtons';
import Report from '../components/ReportFrame';
import setFilter from '../helpers';
import { PowerBiView, UserReportViewType } from '../models';
import {
  selectEmbedToken,
  selectEmbedUrl,
  selectInitialPage,
  selectPages,
  selectPermission,
  selectRedirectedPage,
  selectReportId,
  selectInitialTab,
} from '../reducer';
import { SAFETY_INTERVAL } from '../sagas';
import { getApiStatus } from '../../../core/utils';

export class PowerBiReport extends PureComponent {
  static propTypes = {
    intl: PropTypes.shape({}).isRequired,
    accessToken: PropTypes.string,
    embedUrl: PropTypes.string,
    reportId: PropTypes.string,
    currentSite: PropTypes.string,
    multiSiteExtIds: PropTypes.arrayOf(PropTypes.string),
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        selected: PropTypes.bool,
      })
    ).isRequired,
    permissionLevel: PropTypes.number.isRequired,
    setPermissionLevel: PropTypes.func.isRequired,
    fetchSites: PropTypes.func,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string,
        displayName: PropTypes.string,
        order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }).isRequired
    ),
    initialPage: PropTypes.string,
    initialTab: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.report = null;
    this.state = {
      error: false,
      errorMessage: '',
      pageLoaded: false,
      reload: false,
      currentPage: null,
      view: PowerBiView.Single,
      containsSingle: true,
    };
  }

  componentDidMount() {
    if (this.props.accessToken) {
      this.setPageLoaded();
    }

    if (this.props.initialTab === 'Multi') {
      this.setState({ view: PowerBiView.Multi });
    }

    this.props.fetchSites();
  }

  componentDidUpdate(prevProps, prevState) {
    const isAzureConnected = prevProps.pages !== this.props.pages;
    const isPrimarySiteUpdated = prevProps.site !== this.props.site;
    const isMultiSiteSelectionUpdated = prevProps.multiSiteExtIds !== this.props.multiSiteExtIds;

    if (isAzureConnected && this.props.sites.length > 1) {
      this.setPageLoaded();
    }

    if (prevState.currentPage !== this.state.currentPage) {
      this.updateReportPage();
    }

    if (
      (prevState.view !== this.state.view && this.report) ||
      (isPrimarySiteUpdated && this.report) ||
      (isMultiSiteSelectionUpdated && this.report)
    ) {
      this.userReportFilters();
    }
  }

  componentWillUnmount() {
    this.report = null;
  }

  updateReportPage() {
    if (this.report && this.state.currentPage) {
      this.report.setPage(this.state.currentPage);
    }
  }

  setCurrentPage = (name) => {
    this.setState({
      currentPage: name,
      error: false,
      pageLoaded: true,
    });
  };

  reloadAnalyticsData = () => {
    this.setState({
      reload: true,
    });

    setTimeout(() => {
      this.setState({
        reload: false,
      });
    }, 500);
    this.props.fetchReport(SAFETY_INTERVAL, '');
  };

  setPageLoaded = (value = true) => {
    const multiInitialPage = result(
      find(this.props.pages, (page) => page.displayName.includes('Multi')),
      'name'
    );
    const redirectedPage = this.props.pages.find((page) =>
      page.displayName.includes(this.props.redirectedPage)
    );
    this.setState(
      (state) => {
        if (!this.props.initialPage) {
          return {
            ...state,
            pageLoaded: value,
            view: PowerBiView.Multi,
            containsSingle: this.props.initialPage ? true : false,
            currentPage: multiInitialPage,
          };
        } else if (redirectedPage) {
          return {
            ...state,
            pageLoaded: value,
            currentPage: redirectedPage.name,
          };
        } else {
          return {
            ...state,
            pageLoaded: value,
            currentPage: this.props.initialPage,
          };
        }
      },
      () => this.checkUserViewAccess(multiInitialPage)
    );
  };

  getReportComponent = () => {
    const { currentPage, pageLoaded, reload } = this.state;

    return pageLoaded || reload ? (
      <Report
        accessToken={this.props.accessToken}
        embedUrl={this.props.embedUrl}
        embedId={this.props.reportId}
        pageName={currentPage}
        onLoad={this.handleReport}
      />
    ) : (
      <div className="powerbi-loading">
        <Loader />
      </div>
    );
  };

  checkUserViewAccess = (multiPageExists) => {
    if (multiPageExists) {
      this.props.setPermissionLevel(UserReportViewType.Multi);
    } else {
      this.props.setPermissionLevel(UserReportViewType.Single);
    }

    this.props.setAnalyticsPage(null);
  };

  defaultErrorHandler = (error) => {
    this.setState({
      pageLoaded: false,
      error: true,
      errorMessage: this.props.intl.formatMessage(
        { id: 'powerbiError' },
        { error: error?.detailedMessage || 'Error Occurred' }
      ),
    });
  };

  handleFilterRemoval = () => {
    if (this.report) {
      this.report.removeFilters();
    } else {
      const newError = {
        detailedMessage: 'Something went wrong with filtering',
      };
      this.defaultErrorHandler(newError);
    }
  };

  handleMultiSiteSelection = (primarySite) => {
    const hostname = window.location.hostname;
    const isWashClub =
      hostname === 'portal.wash.club' ||
      hostname === 'portal.staging-wash.club' ||
      hostname === 'portal.dev-wash.club';
    const conditions = this.props.sites.map((availableSites) => {
      return {
        operator: 'Is',
        value: isWashClub ? availableSites.extId : availableSites.externalId,
      };
    });

    this.report.setFilters([setFilter(conditions, isWashClub)]).catch(this.defaultErrorHandler);
  };

  userReportFilters = () => {
    const currentSelectedSite = find(
      this.props.sites,
      (site) => site.id === Number(this.props.currentSite)
    );

    const hostname = window.location.hostname;
    const isWashClub =
      hostname === 'portal.wash.club' ||
      hostname === 'portal.staging-wash.club' ||
      hostname === 'portal.dev-wash.club';
    if (this.state.view === PowerBiView.Single) {
      const conditions = [
        {
          operator: 'Is',
          value: isWashClub ? currentSelectedSite.extId : currentSelectedSite.externalId,
        },
      ];

      this.report.setFilters([setFilter(conditions, isWashClub)]).catch(this.defaultErrorHandler);
    } else {
      this.handleFilterRemoval();
    }
  };

  handleReport = (report) => {
    if (!this.report) {
      if (!report) {
        this.defaultErrorHandler();
      }

      this.report = report;
    }

    if (report && report.iframe && report.iframe.contentWindow) {
      this.userReportFilters();
    }
  };

  changeView = (view) => {
    const initialMultiPage = result(
      find(this.props.pages, (page) => page.displayName.includes(PowerBiView.Multi)),
      'name'
    );

    this.setState({
      currentPage: view === PowerBiView.Single ? this.props.initialPage : initialMultiPage,
      view,
    });
  };

  showErrorMessage = () => {
    return <div className="powerbi-error">{this.state.errorMessage}</div>;
  };
  render() {
    const { currentPage, view } = this.state;
    return (
      <div className={classNames('container-fluid')}>
        <PermissionViewButtons
          permissionLevel={this.props.permissionLevel}
          view={view}
          changeView={this.changeView}
          includesSingle={this.state.containsSingle}
        />
        {!this.state.reload && !this.props.loading && this.props?.pages && (
          <section className={classNames('page-main-content', 'powerbi-container')}>
            <div className="powerbi-tab-container">
              <div className="powerbi-tab-scroll">
                <NavTabs
                  userView={view}
                  pages={this.props.pages}
                  currentPage={currentPage}
                  setCurrentPage={this.setCurrentPage}
                />
              </div>
            </div>
            {this.state.error ? this.showErrorMessage() : this.getReportComponent()}
          </section>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSites,
      setPermissionLevel,
      setAnalyticsPage,
      fetchReport,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  const hostname = window.location.hostname;
  const isWashClub =
    hostname === 'portal.wash.club' ||
    hostname === 'portal.staging-wash.club' ||
    hostname === 'portal.dev-wash.club';
  const key = isWashClub ? 'Manager-Analytics-WashClub' : 'Manager-Analytics';
  const loader = getApiStatus(state, GET_REPORT);
  return {
    loading: loader,
    accessToken: selectEmbedToken(state, key),
    embedUrl: selectEmbedUrl(state, key),
    reportId: selectReportId(state, key),
    pages: selectPages(state, key),
    site: selectSiteName(state),
    sites: selectSites(state),
    currentSite: selectSite(state),
    permissionLevel: selectPermission(state, key),
    initialPage: selectInitialPage(state, key),
    redirectedPage: selectRedirectedPage(state, key),
    multiSiteExtIds: selectMultiSiteSelectionExternalIds(state),
    initialTab: selectInitialTab(state),
  };
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(PowerBiReport);
