import { API_REQUEST } from '../../core/actions';
import { patchSite } from '../../core/api/sites';
import { fetchSiteSettings } from '../../core/actions';
import * as siteDetails from '../../core/api/task-site-details';
import { postTemplateData } from '../../core/api/template-data';
import * as userGroupApi from '../../core/api/user-groups';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import { generateToastr } from '../../core/toastMessages';

export const LOAD_TEMPLATE_DATA = 'LOAD_TEMPLATE_DATA';
export const UPDATE_SITE_SETTINGS = 'UPDATE_SITE_SETTINGS';
export const SET_SITE_SETTINGS = 'SET_SITE_SETTINGS';
export const FETCH_SITE_OPERATION_TYPE = 'FETCH_SITE_OPERATION_TYPE';
export const FETCH_SITE_MODEL_YEAR = 'FETCH_SITE_MODEL_YEAR';
export const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS';
export const SET_TASK_IN_DETAIL = 'SET_TASK_IN_DETAIL';

export const loadTemplateData = (data) => ({
  type: API_REQUEST,
  callAPI: postTemplateData,
  action: LOAD_TEMPLATE_DATA,
  data,
});

export const updateSiteSettings = (settings) => {
  return {
    type: API_REQUEST,
    callAPI: patchSite,
    action: UPDATE_SITE_SETTINGS,
    data: settings,
    closeModal: true,
  };
};

export const getSiteModelYears = () => ({
  type: API_REQUEST,
  callAPI: siteDetails.fetchSiteModelYears,
  action: FETCH_SITE_MODEL_YEAR,
  meta: { permissionKey: viewPermissionRefs.settings },
});

export const getSiteOperationTypes = () => ({
  type: API_REQUEST,
  callAPI: siteDetails.fetchSiteOperationTypes,
  action: FETCH_SITE_OPERATION_TYPE,
  meta: { permissionKey: viewPermissionRefs.settings },
});

export const fetchUserGroups = (data) => {
  return {
    type: API_REQUEST,
    callAPI: userGroupApi.fetchUserGroups,
    action: FETCH_USER_GROUPS,
    data,
  };
};
