import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import datalabelsPlugin from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import { forEach, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import DataTable from '../../../core/components/DataTable';
import Loader from '../../../core/components/Loader';
import { getMultiSites } from '../../../core/storage';
import { getApiStatus } from '../../../core/utils';
import { selectSiteExternalId, selectSiteExtId, selectSiteId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import DailyWashCountKpi from './DailyWashCountKpi';
import { selectClosestSites } from '../reducer';

ChartJS.register(
  datalabelsPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

interface Props {
  hourlyWashCounts: {
    count: number;
    countByProduct: { [key: string]: number };
    countsBySiteId: { [key: number]: number };
  }[];
  closestHourlyWashCounts: {
    count: number;
    countByProduct: { [key: string]: number };
    countsBySiteId: { [key: number]: number };
  }[];
  washCounts: { date: moment.Moment; count: number }[];
  fromDate: string;
  untilDate: string;
}
type SingleSiteData = {
  _id: any;
  hour: string;
  count: number;
};

const generateData = (
  hourlyWashCounts: {
    count: number;
    countByProduct: { [key: string]: number };
    countsBySiteId: { [key: number]: number };
  }[],
  multiSite: any[],
  primarySiteId: any
) => {
  const data: SingleSiteData[] = map(
    hourlyWashCounts,
    (counts: { count: number }, hour: number, i: any) => ({
      _id: i,
      hour: moment(hour, 'hour').format('ha'),
      count: counts.count,
    })
  );

  if (multiSite?.length > 0) {
    const siteIdList = [primarySiteId, ...multiSite.map((site) => site.id)];
    const countsBySite = Object.entries(hourlyWashCounts).map(([hour, { countsBySiteId }]) => ({
      [hour]: siteIdList.reduce((newCountsBySiteId, siteId) => {
        newCountsBySiteId[siteId] = countsBySiteId?.[siteId] || 0;
        return newCountsBySiteId;
      }, {} as { [key: number]: number }),
    }));

    const multiSiteData: [string, ...number[]][] = data.map((item) => [item.hour]);
    multiSiteData.forEach(([time], index) => {
      const countsObj = countsBySite[index];
      const countsArr = siteIdList.map(
        (siteId) => countsObj[moment(data[index].hour, 'h a').format('H')][siteId] || 0
      );
      multiSiteData[index] = [data[index].hour, ...countsArr];
    });

    return multiSiteData;
  } else {
    return data;
  }
};

export const StackedBarChart = (props: Props): JSX.Element => {
  const [chartInfo, setChartInfo] = useState({ data: { datasets: [] }, options: {} });
  const [chartView, setChartView] = useState(true);

  const [mobileView, setMobileView] = useState({
    anchor: 'end',
    maintainAspectRatio: true,
  });
  const barRef = useRef<any>(null);
  const intl = useIntl();
  const fromDate = moment(props.fromDate);
  const untilDate = moment(props.untilDate);
  const primarySite = useSelector(selectSiteExtId);
  const primarySiteId = useSelector(selectSiteId);
  const primarySiteExternalID = useSelector(selectSiteExternalId);
  const currentDate = moment();
  const sameDay: boolean = fromDate.isSame(untilDate, 'date');
  const loadingApiCall = useSelector((state) =>
    getApiStatus(state, 'OVERVIEW_GET_MYCHARTS_WASHCOUNTS')
  );
  let multiSite: any[] = useSelector(selectMultiSiteSelection);
  const closestSite: any[] = useSelector(selectClosestSites);

  const storedMultiSites: any = getMultiSites();
  const createChartData = () => {
    const dataPoints: number[] = [];
    const labels: string[] = [];
    forEach(props.hourlyWashCounts, (counts: { count: number }, hour: any) => {
      const currentHour = moment().hour();
      const hourlyCountLabel = moment(hour, 'hour').hour();

      if (
        (sameDay && fromDate.isSame(currentDate, 'date') && hourlyCountLabel <= currentHour) ||
        !fromDate.isSame(currentDate, 'date')
      ) {
        labels.push(moment(hour, 'hour').format('ha'));
        dataPoints.push(counts.count);
      }
    });

    const options: any = {
      animation: {
        duration: 1,
      },
      plugins: {
        datalabels: {
          display: true,
          align: 'center',
          anchor: mobileView.anchor,
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: 'Hourly Wash Counts',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      },
    };

    const data: any = {
      labels,
      datasets: [
        {
          label: 'All',
          data: dataPoints,
          backgroundColor: '#FFCE00',
          borderRadius: 5,
        },
      ],
    };

    setChartInfo({
      data,
      options,
    });
  };

  const initializeDataTable = () => {
    let headers: { name: string }[];
    if (storedMultiSites) {
      multiSite = JSON.parse(storedMultiSites);
    }

    const data = generateData(props.hourlyWashCounts, multiSite, primarySiteId);

    if (storedMultiSites && multiSite?.length > 0) {
      headers = [{ name: 'Hour' }];
      headers.push({ name: primarySiteExternalID });

      multiSite.forEach((site: { externalId: any }) => {
        headers.push({ name: site.externalId });
      });
    } else {
      headers = [{ name: 'Hour' }, { name: 'Count' }];
    }
    return {
      data,
      headers,
    };
  };

  const triggerBarDirection = (chart: ChartJS | null) => {
    const chartWidth = chart?.width;

    if (!chartWidth) {
      return;
    }

    const isPortrait = window.matchMedia('(orientation: portrait)').matches;

    if (isPortrait) {
      setMobileView({
        anchor: 'center',
        maintainAspectRatio: false,
      });
    } else {
      setMobileView({
        anchor: 'end',
        maintainAspectRatio: true,
      });
    }

    chart.render();
  };

  const handleMobileView = (): JSX.Element => {
    if (mobileView.maintainAspectRatio) {
      return (
        <div>
          <Bar
            ref={barRef}
            options={{
              ...chartInfo.options,
              indexAxis: 'x',
              maintainAspectRatio: mobileView.maintainAspectRatio,
            }}
            data={chartInfo.data}
          />
        </div>
      );
    }
    return (
      <div className="overview-wash-chart">
        <Bar
          ref={barRef}
          options={{
            ...chartInfo.options,
            indexAxis: 'y',
            maintainAspectRatio: mobileView.maintainAspectRatio,
          }}
          data={chartInfo.data}
        />
      </div>
    );
  };
  const handleChartDraw = (): JSX.Element => {
    if (!loadingApiCall) {
      if (chartView) {
        return handleMobileView();
      }
      return <></>;
    }
    return <Loader />;
  };

  useEffect(createChartData, [props.hourlyWashCounts]);
  useEffect(() => {
    const chart = barRef.current;
    triggerBarDirection(chart);
  }, [chartView]);
  useEffect(() => {
    if (multiSite.length > 0) {
      setChartView(!chartView);
    }
  }, [multiSite, primarySite]);
  useEffect(() => {
    if (multiSite.length > 0) {
      setChartView(false);
    } else {
      setChartView(true);
    }
  }, [multiSite, primarySite]);

  return (
    <div>
      <section className="container-fluid overview-chart-card">
        <div className="chart-title-section">
          <div className="chart-title">{intl.formatMessage({ id: 'hourlyWashCounts' })}</div>
          <div className="button-container">
            <button className="button small" onClick={() => setChartView(!chartView)}>
              {chartView
                ? intl.formatMessage({ id: 'tableView' })
                : intl.formatMessage({ id: 'chartView' })}
            </button>
          </div>
        </div>
        <span className={!chartView ? 'overview-wash-table-hide' : ''}>{handleChartDraw()}</span>
        <span className={!chartView ? '' : 'overview-wash-table-hide'}>
          <DataTable {...initializeDataTable()} />
        </span>
      </section>
      <section className="container-fluid overview-chart-card seven-day-cars-washed">
        <div className="chart-title-section">
          <div className="chart-title">{intl.formatMessage({ id: 'sevenDayWash' })}</div>
        </div>
        <div className="row">
          <div className="col-12">
            {!loadingApiCall ? (
              <DailyWashCountKpi
                hourlyWashCounts={props.hourlyWashCounts}
                washCounts={props.washCounts}
              />
            ) : (
              <div className="powerbi-loading">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default StackedBarChart;
