import {
  forEach,
  isEmpty,
  isEqual,
  isNumber,
  isString,
  keys,
  map,
  omit,
  pick,
  sortBy,
  sumBy,
  values,
} from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import Button from '../../../core/components/Button';
import DataTable from '../../../core/components/DataTable';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import PageActions from '../../../core/components/PageActions';
import UserGroupPermissions, {
  addPermissionRefs,
  viewPermissionRefs,
  findPermissionRef,
} from '../../../core/components/UserGroupPermissions';
import { getApiStatus } from '../../../core/utils';
import { formatAmount } from '../../../utils/money-util';
import { fetchProducts } from '../../products/actions';
import { selectSiteExtId, selectSiteName, selectSiteTotalCarCount } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import * as actions from '../actions';
import FleetPayAsYouGoDiscounts from '../components/FleetPayAsYouGoDiscounts';
import Logo from '../components/Logo';
import PayAsYouGoDiscounts from '../components/PayAsYouGoDiscounts';
import PayAsYouGoTable from '../components/PayAsYouGoTable';
import SummaryTable from '../components/SummaryTable';
import { ServiceType } from '../models';
import {
  selectAdjustments,
  selectAdjustmentsPaginationInfo,
  selectCombinedCounts,
  selectDiscounts,
  selectFleetPayAsYouGoCounts,
  selectFleetPayAsYouGoDiscounts,
  selectFromDate,
  selectPayAsYouGoCounts,
  selectPayAsYouGoDiscounts,
  selectRefunds,
  selectShowingCoreCountsDate,
  selectUntilDate,
  selectWashCounts,
} from '../reducer';
import { WashCountsPropTypes } from '../types';
import HourlyWashCounts from './HourlyWashCounts';
import WashChart from './WashCountChart';
import { WashCountsAnalytics } from './WashCountsAnalytics';
import UserPermissions from '../../../core/components/UserPermissions';
import { fetchReport } from '../../analytics/actions';
import { SAFETY_INTERVAL } from '../../analytics/sagas';
import Loader from '../../../core/components/Loader';
import { selectUserGroup } from '../../../store/authReducer';

const isNumberString = (value) => {
  return isString(value) && !isEmpty(value);
};

class WashCounts extends Component {
  static propTypes = WashCountsPropTypes;

  constructor(props) {
    super(props);
    this.state = {
      tabType: false,
      reload: false,
      interiorFilter: false,
    };
  }

  componentDidMount() {
    this.props.fetchProducts();
    this.fetchAsOfDate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.extId !== this.props.extId || prevProps.multiSites !== this.props.multiSites) {
      this.fetchAsOfDate();
    }
    const currentDates = this.getFormattedDates();
    const prevDates = [
      prevProps.fromDate.format('YYYY-MM-DD'),
      prevProps.untilDate.format('YYYY-MM-DD'),
    ];
    if (!isEqual(prevDates, currentDates)) {
      this.fetchAsOfDate();
    }
  }

  getDiscountsSubRows = (discounts) => {
    const subRowObjects = discounts.map((discount) => ({
      header: discount.name,
    }));

    const subRows = [];
    subRowObjects.forEach((object) => {
      const parent = discounts.find((row) => row.name === object.header);
      if (parent && parent.promotionItems) {
        subRows.push({
          parentRow: object.header,
          rows: this.initializeCouponDataTable(parent.promotionItems, object.columns),
        });
      }
    });

    return subRows;
  };

  getDiscountsSubRowInfo = (discounts) => ({
    identifier: 'name',
    displayProperty: 'name',
    subRows: this.getDiscountsSubRows(discounts),
  });

  getFormattedDates = () => [
    this.props.fromDate.format('YYYY-MM-DD'),
    this.props.untilDate.format('YYYY-MM-DD'),
  ];

  getRowClickDialog = (data) => {
    this.props.showDialog('SHOW_ADJUSTMENT_NOTES', data);
  };

  getShowingCountDate = () => {
    if (!this.allLoadingsDone()) {
      return <span>&nbsp;</span>;
    }

    const showingCoreCountsDate = this.props.showingCoreCountsDate || {};

    const fromDate = moment(showingCoreCountsDate.fromDate).format('MM/DD/YYYY');
    const untilDate = moment(showingCoreCountsDate.untilDate).format('MM/DD/YYYY');
    const formatMessage = this.props.intl.formatMessage;

    const showWeekNumber = () => {
      const startOfWeek = moment(this.props.showingCoreCountsDate.fromDate)
        .startOf('week')
        .format('MM/DD/YYYY');
      const endOfWeek = moment(this.props.showingCoreCountsDate.fromDate)
        .endOf('week')
        .format('MM/DD/YYYY');

      const isWeek = startOfWeek === fromDate && endOfWeek === untilDate;

      if (!isWeek) {
        return '';
      }

      return (
        <span>
          {' '}
          {formatMessage({ id: 'week' })}{' '}
          <b>{moment(this.props.showingCoreCountsDate.fromDate).week()}</b>.
        </span>
      );
    };

    if (fromDate === untilDate) {
      return (
        <span>
          {formatMessage({ id: 'showingCountsFrom' })}
          <b>{fromDate}</b>.
        </span>
      );
    }

    return (
      <span>
        {formatMessage({ id: 'showingCountsFrom' })}
        <b>{fromDate}</b> {formatMessage({ id: 'to' })} <b>{untilDate}</b>.{showWeekNumber()}
      </span>
    );
  };

  initializeDataTable(counts, columns, opts) {
    const { intl } = this.props;

    const calcDifference = (run, paid) => {
      if (!isNumber(run) || !isNumber(paid)) {
        return '';
      }
      return run - paid;
    };

    const pickDataByCols = (data, cols) => {
      if (!data) {
        return data;
      }
      if (cols) {
        cols.push('_id');
      }
      return cols ? pick(data, cols) : data;
    };

    const changeOpenGateToBailOutVehicle = (itemName) => {
      const openGateName = intl.formatMessage({ id: 'openGate' });

      return itemName === openGateName ? intl.formatMessage({ id: 'bailOutVehicle' }) : itemName;
    };

    const getItemName = (item) => {
      return item.name
        ? changeOpenGateToBailOutVehicle(item.name)
        : intl.formatMessage({ id: 'notAvailable' });
    };

    const createRowDataFromItem = (item) => {
      return {
        _id: item.id,
        name: getItemName(item),
        runCount: isNumber(item.runCount) ? item.runCount : '',
        // Show adjustment button for POS count in operation rows only
        popCount: isNumber(item.count) ? item.count : '',
        difference: calcDifference(item.runCount, item.count),
        price:
          isNumberString(item.price) && item.price !== ''
            ? formatAmount(item.price, item.currency || 'usd', true)
            : '',
        priceWithoutTax:
          isNumberString(item.priceWithoutTax) && item.priceWithoutTax !== ''
            ? formatAmount(item.priceWithoutTax, item.currency || 'usd', true)
            : '',
        amount: isNumberString(item.amount)
          ? formatAmount(item.amount, item.currency || 'usd', true)
          : '',
        tax: isNumberString(item.tax) ? formatAmount(item.tax, item.currency || 'usd', true) : '',
        total: isNumberString(item.total) ? formatAmount(item.total, item.currency || 'usd') : '',
      };
    };

    const data = counts.map((i) => pickDataByCols(createRowDataFromItem(i), columns));

    const templateItem = createRowDataFromItem({});
    const dataKeys = keys(omit(pickDataByCols(templateItem, columns), ['_id']));
    const headers = dataKeys.map((key) => {
      if (key !== 'tax') {
        return { name: intl.formatMessage({ id: key }) };
      }
      return { name: intl.formatMessage({ id: 'totalTax' }) };
    });

    // Add total row if there is data and we have the totals
    if (data.length && (!opts || !opts.noTotal)) {
      const totalItem = {
        id: `total-${counts.length}`,
        name: 'Total',
        runCount: sumBy(counts, (i) => i.runCount),
        count: sumBy(counts, (i) => i.count),
        amount: String(sumBy(counts, (i) => (i.amount ? parseFloat(i.amount) : 0))),
        tax: String(sumBy(counts, (i) => (i.tax ? parseFloat(i.tax) : 0))),
        total: String(sumBy(counts, (i) => (i.total ? parseFloat(i.total) : 0))),
      };
      data.push(pickDataByCols(createRowDataFromItem(totalItem, false), columns));
    }

    const options = {
      styleHeader: {
        backgroundColor: '#e6e6ea',
      },
    };

    return {
      data,
      headers,
      options,
    };
  }

  createDiscountsWithCountAndAmount = (discounts) => {
    return map(discounts, (discount) => {
      return {
        name: discount.name,
        count: discount.count
          ? discount.count
          : sumBy(
              map(discount.promotionItems, (item) => item.count),
              (i) => parseFloat(i)
            ),
        amount: discount.totalDiscount
          ? formatAmount(discount.totalDiscount, discount.currency || 'usd', true)
          : this.calcSubGroupCouponDiscounts(discount.promotionItems),
      };
    });
  };

  calcSubGroupCouponDiscounts = (promotionDiscount) => {
    const sumTotals = sumBy(
      map(promotionDiscount, (d) => d.totalDiscount),
      (t) => parseFloat(t)
    );
    return formatAmount(sumTotals, 'usd', true);
  };

  calcCouponTotals = (discounts) => {
    return map(discounts, (discount) => {
      if (discount.promotionItems) {
        return sumBy(
          map(discount.promotionItems, (item) => item.totalDiscount),
          (t) => parseFloat(t)
        );
      }
      return parseFloat(discount.totalDiscount);
    });
  };

  reduceTotals = (promotionPrices) => {
    let promotionTotalPrice;

    if (Array.isArray(promotionPrices) && promotionPrices.length >= 1) {
      promotionTotalPrice = promotionPrices.reduce((a, b) => parseFloat(a) + parseFloat(b));
    } else {
      promotionTotalPrice = promotionPrices;
    }

    return promotionTotalPrice;
  };

  initializeCouponDataTable(discounts) {
    const { intl } = this.props;

    const discountsWithCountAndTotals = this.createDiscountsWithCountAndAmount(discounts);
    const data = sortBy(discountsWithCountAndTotals, 'name');

    // calculate totals for each row
    if (data.length >= 1) {
      const totalCounts = sumBy(discountsWithCountAndTotals, (i) => i.count);
      const couponTotals = this.calcCouponTotals(discounts);
      const reducedTotals = this.reduceTotals(couponTotals);

      data.push({
        name: 'Total',
        count: totalCounts,
        totalDiscount: formatAmount(reducedTotals, 'usd'),
        _highlightInformation: {
          index: 2,
          highlight: true,
        },
      });
    }

    const headers = [
      { name: intl.formatMessage({ id: 'name' }) },
      { name: intl.formatMessage({ id: 'purchases' }) },
      { name: intl.formatMessage({ id: 'totalDiscount' }) },
    ];

    const options = {
      styleHeader: {
        backgroundColor: '#e6e6ea',
      },
    };

    return {
      data,
      headers,
      options,
    };
  }

  configureAddDialog() {
    const { intl, addWashCounts, fromDate, untilDate, extId } = this.props;
    return {
      title: intl.formatMessage({ id: 'addWashCounts' }),
      onSubmit: (data) => {
        addWashCounts(data, fromDate.toISOString(), untilDate.toISOString(), extId);
      },
      isNew: true,
    };
  }

  refreshAnalyticsReport = () => {
    this.setState({ reload: true });
    setTimeout(() => {
      this.setState({ reload: false });
    }, 500);
    this.props.fetchReport(SAFETY_INTERVAL, 'Wash Counts Tab');
  };

  fetchAsOfDate = (signal) => {
    const {
      extId,
      fetchCoreCounts,
      fetchCorePayAsYouGoCounts,
      fetchCoreFleetPayAsYouGoCounts,
      fetchCoreFleetPayAsYouGoDiscounts,
      fetchCoreDiscounts,
      fetchCorePayAsYouGoDiscounts,
      fetchCoreRefunds,
    } = this.props;

    if (extId) {
      const [from, until] = this.getFormattedDates();

      fetchCoreCounts(from, until, signal, extId);
      fetchCorePayAsYouGoCounts(from, until, signal, extId);
      fetchCoreFleetPayAsYouGoCounts(from, until, signal, extId);
      fetchCoreFleetPayAsYouGoDiscounts(from, until, signal, extId);
      fetchCoreDiscounts(from, until, signal, extId);
      fetchCorePayAsYouGoDiscounts(from, until, signal, extId);
      fetchCoreRefunds(from, until, signal, extId);
    }
  };

  goToAdjustmentsPage = () => {
    const id = this.props.match.params.site;
    this.props.history.push(`/${id}/washcounts/adjustments`);
  };

  allLoadingsDone = () => {
    const loaderFlags = values(this.props.loaders);

    return loaderFlags.every((loader) => loader === false);
  };

  filterOnProperty = (dataArray, filterProperty = '', filterValue = '', exclude = true) => {
    const compareProperties = (count) => count[filterProperty] === filterValue;
    if (this.state.interiorFilter) {
      dataArray = dataArray.filter((count) => {
        return count.washcountCategory === 'interior';
      });
    }
    if (filterProperty === '') return dataArray;
    return dataArray.filter((count) => {
      return exclude ? !compareProperties(count) : compareProperties(count);
    });
  };

  handleSwitchButton = () => {
    this.setState({ tabType: !this.state.tabType });
  };

  handleInteriorFilter = () => {
    this.setState({ interiorFilter: !this.state.interiorFilter });
  };

  render() {
    const {
      intl,
      washCounts,
      payAsYouGoCounts,
      fleetPayAsYouGoCounts,
      fleetPayAsYouGoDiscounts,
      discounts,
      payAsYouGoDiscounts,
      combinedCounts,
      loaders,
      refunds,
    } = this.props;
    const filteredWashCounts = this.state.interiorFilter
      ? washCounts.filter((wash) => wash.washcountCategory === 'interior')
      : washCounts;

    return (
      <div className="container-fluid wash-counts">
        <div className="flex powerbi-button-container">
          <UserGroupPermissions
            minRole="OperatorGroupAdmin"
            itemRef={viewPermissionRefs.interior_filter}
          >
            <button
              className={`powerbi-right-buttons powerbi-left-buttons ${
                this.state.interiorFilter ? 'powerbi-active' : 'powerbi-inactive'
              }`}
              onClick={this.handleInteriorFilter}
            >
              {intl.formatMessage({ id: 'interiorDetails' })}
            </button>
          </UserGroupPermissions>
          {findPermissionRef(
            this.props.userGroup.permissions,
            viewPermissionRefs.wash_analytics
          ) && (
            <button
              className={`powerbi-right-buttons powerbi-left-buttons ${
                this.state.reload ? 'powerbi-active' : 'powerbi-inactive'
              }`}
              onClick={this.refreshAnalyticsReport}
            >
              <i className="uil uil-redo"></i>
            </button>
          )}
        </div>
        {findPermissionRef(this.props.userGroup.permissions, viewPermissionRefs.wash_analytics) ? (
          <div>{!this.state.reload ? <WashCountsAnalytics /> : <Loader />}</div>
        ) : (
          <div>
            <div className="table-buttons no-break overview-buttons">
              <div>
                <DateRangeSelector
                  dates={[this.props.fromDate, this.props.untilDate]}
                  onSubmit={(dates) => {
                    this.props.setFromDate(dates.from);
                    this.props.setUntilDate(dates.until);
                  }}
                  isLoading={!!loaders.areCoreCountsLoading}
                />
              </div>
              <div>
                <div className="row no-gutters justify-content-end">
                  {!isMobile && (
                    <ReactToPrint
                      trigger={() => (
                        <Button styleName="white mr-2 print-button">
                          {intl.formatMessage({ id: 'print' })}
                        </Button>
                      )}
                      content={() => this.printableComp}
                    />
                  )}
                  <PageActions
                    actionFunction={this.props.showDialog}
                    actions={[
                      {
                        dialogType: 'ADD_WASH_COUNTS',
                        dialogProps: this.configureAddDialog(),
                        text: intl.formatMessage({ id: 'addWashCounts' }),
                        classes: ['primary'],
                        permissionKey: addPermissionRefs.wash_count,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-3 col-top-gutter wash-chart-container">
                <section>
                  <WashChart
                    intl={intl}
                    washCounts={filteredWashCounts}
                    showLoader={loaders.areCoreCountsLoading}
                    interiorFilter={this.state.interiorFilter}
                  />
                  <HourlyWashCounts interiorFilter={this.state.interiorFilter} />
                </section>
              </div>
              <div
                className="col-md-12 col-lg-9 col-top-gutter wash-tables wc-main-container shadowed"
                ref={(el) => {
                  this.printableComp = el;
                }}
              >
                <div className="info-for-print">
                  <Logo />
                  <span className="wc-site-name">{this.props.siteName}</span>
                </div>
                <section className="wash-details wc-table-container">
                  <div className="date-page-actions-container">
                    {this.getShowingCountDate()}
                    {!!viewPermissionRefs.wash_count_adjustments && (
                      <PageActions
                        actionFunction={this.goToAdjustmentsPage}
                        actions={[
                          {
                            text: 'Adjustments',
                            permissionKey: viewPermissionRefs.wash_count_adjustments,
                          },
                        ]}
                      />
                    )}
                  </div>
                  <h2 className="wc-table-header">Wash Details</h2>
                  <DataTable
                    {...this.initializeDataTable(
                      this.filterOnProperty(
                        combinedCounts.washes,
                        'serviceType',
                        ServiceType.VacuumCleaner
                      ),
                      [
                        'name',
                        'runCount',
                        'popCount',
                        'difference',
                        'priceWithoutTax',
                        'tax',
                        'total',
                      ],
                      { showAdjustment: true }
                    )}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areCoreCountsLoading}
                  />
                </section>
                <PayAsYouGoTable
                  intl={intl}
                  header={intl.formatMessage({ id: 'payPerWash' })}
                  payAsYouGoCounts={payAsYouGoCounts}
                  showLoader={loaders.areCoreCountsLoading}
                  propertyFilter={this.filterOnProperty}
                  interiorFilter={this.state.interiorFilter}
                />
                <PayAsYouGoTable
                  intl={intl}
                  header={intl.formatMessage({ id: 'fleetPayPerWash' })}
                  payAsYouGoCounts={fleetPayAsYouGoCounts}
                  showLoader={loaders.areCoreCountsLoading}
                  propertyFilter={this.filterOnProperty}
                  interiorFilter={this.state.interiorFilter}
                />
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Vacuums</h2>
                  <DataTable
                    {...this.initializeDataTable(
                      this.filterOnProperty(
                        combinedCounts.washes,
                        'washes',
                        ServiceType.VacuumCleaner,
                        false
                      ),
                      [
                        'name',
                        'runCount',
                        'popCount',
                        'difference',
                        'priceWithoutTax',
                        'tax',
                        'total',
                      ],
                      { showAdjustment: true }
                    )}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areCoreCountsLoading}
                  />
                </section>
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Coupons</h2>
                  <DataTable
                    {...this.initializeCouponDataTable(this.filterOnProperty(discounts))}
                    subRowInfo={this.getDiscountsSubRowInfo(discounts ? discounts : [])}
                    addedClass="wash-count-table shrinked-table table-subrows"
                    showLoader={loaders.areDiscountsLoading}
                  />
                </section>
                <PayAsYouGoDiscounts
                  intl={intl}
                  data={payAsYouGoDiscounts}
                  showLoader={loaders.areDiscountsLoading}
                />
                <FleetPayAsYouGoDiscounts
                  intl={intl}
                  data={fleetPayAsYouGoDiscounts}
                  showLoader={loaders.areDiscountsLoading}
                />
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Add-On Details</h2>
                  <DataTable
                    {...this.initializeDataTable(this.filterOnProperty(combinedCounts.items), [
                      'name',
                      'popCount',
                      'price',
                      'amount',
                      'tax',
                      'total',
                    ])}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areDiscountsLoading}
                  />
                </section>
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Gift Card Details</h2>
                  <DataTable
                    {...this.initializeDataTable(this.filterOnProperty(combinedCounts.giftCards), [
                      'name',
                      'popCount',
                      'price',
                      'amount',
                      'tax',
                      'total',
                    ])}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areDiscountsLoading}
                  />
                </section>
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Insta Club Details</h2>
                  <DataTable
                    {...this.initializeDataTable(this.filterOnProperty(combinedCounts.plans), [
                      'name',
                      'popCount',
                      'price',
                      'amount',
                      'tax',
                      'total',
                    ])}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areDiscountsLoading}
                  />
                </section>
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Rewash Details</h2>
                  <DataTable
                    {...this.initializeDataTable(
                      this.filterOnProperty(combinedCounts.rewashes, 'name', 'Open Gate'),
                      ['name', 'popCount', 'price', 'total']
                    )}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areDiscountsLoading}
                  />
                </section>
                <section className="wc-table-container">
                  <h2 className="wc-table-header">Misc Events</h2>
                  <DataTable
                    {...this.initializeDataTable(
                      this.filterOnProperty(combinedCounts.rewashes, 'name', 'Open Gate', false),
                      ['name', 'popCount', 'price', 'total']
                    )}
                    addedClass="wash-count-table shrinked-table"
                    showLoader={loaders.areDiscountsLoading}
                  />
                </section>
                <SummaryTable
                  intl={intl}
                  summaryCounts={combinedCounts.summary}
                  refunds={refunds}
                  loaders={pick(loaders, ['areCoreCountsLoading', 'areRefundsLoading'])}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const getLoaders = (state) => {
  const loaders = {
    areCoreCountsLoading: actions.FETCH_CORE_COUNTS,
    arePayAsYouGoCountsLoading: actions.FETCH_CORE_PAY_AS_YOU_GO_COUNTS,
    areDiscountsLoading: actions.FETCH_CORE_DISCOUNTS,
    arePayAsYouGoDiscountsLoading: actions.FETCH_CORE_PAY_AS_YOU_GO_DISCOUNTS,
    areRefundsLoading: actions.FETCH_CORE_REFUNDS,
  };

  forEach(loaders, (action, key) => {
    loaders[key] = getApiStatus(state, action);
  });

  return loaders;
};

const mapStateToProps = (state) => ({
  washCounts: selectWashCounts(state),
  userGroup: selectUserGroup(state),
  combinedCounts: selectCombinedCounts(state),
  payAsYouGoCounts: selectPayAsYouGoCounts(state),
  fleetPayAsYouGoCounts: selectFleetPayAsYouGoCounts(state),
  fleetPayAsYouGoDiscounts: selectFleetPayAsYouGoDiscounts(state),
  fromDate: selectFromDate(state),
  untilDate: selectUntilDate(state),
  discounts: selectDiscounts(state),
  payAsYouGoDiscounts: selectPayAsYouGoDiscounts(state),
  adjustments: selectAdjustments(state),
  adjustmentsPaginationInfo: selectAdjustmentsPaginationInfo(state),
  showingCoreCountsDate: selectShowingCoreCountsDate(state),
  extId: selectSiteExtId(state),
  multiSites: selectMultiSiteSelection(state),
  siteName: selectSiteName(state),
  refunds: selectRefunds(state),
  loaders: getLoaders(state),
  totalCarCount: selectSiteTotalCarCount(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      fetchProducts,
      fetchReport,
      showDialog,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter);

export default enhance(WashCounts);
