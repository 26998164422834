import React from 'react';
import { Field } from 'redux-form';
import { Select } from '../../../core/formValidation';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectRole } from '../../../store/authReducer';
import { hasRole, RoleTypes } from '../../../core/components/Permission';

export const AddressFields = ({
  fields,
  startAddress,
  remainingProducts,
  products,
  handleProductChange,
}) => {
  const intl = useIntl();
  const role = useSelector(selectRole);
  const isOperator = hasRole(role, RoleTypes.Operator);
  const addAddressField = (isNew) => {
    const { id, name } = products[0] || {};
    fields.push({
      index: fields.length,
      address: Number(startAddress + fields.length) || fields.length,
      productId: !isNew && id ? id : null,
      name: !isNew && name ? name : null,
      isNew,
    });
  };

  const createProductList = (index) => {
    const selectedProductForCurrentField = products.find(
      (p) => fields.get(index) && p.id === fields.get(index).productId
    );
    const options = [];

    if (selectedProductForCurrentField) {
      handleProductChange(selectedProductForCurrentField);
      const { id, name } = selectedProductForCurrentField;
      // update the field with the selected product name
      // delete the isNew property
      fields.get(index).name = name;
      delete fields.get(index).isNew;
      options.push(
        <option
          data-testid="product-option"
          id="selected"
          key={`field-${index}-product-${id}-selected`}
          value={id}
        >
          {name}
        </option>
      );
    }

    const remainingOptions = remainingProducts.map((p) => (
      <option
        data-testid="product-option"
        id="remaining"
        key={`field-${index}-product-${p.id}`}
        value={p.id}
      >
        {p.name}
      </option>
    ));

    options.push(...remainingOptions);

    return options;
  };

  return (
    <React.Fragment>
      {fields.map((item, index) => {
        const { isNew } = fields.get(index);
        return (
          <div className="form-inline mb-2" key={index}>
            <div className="mr-2 flex-1">
              <Field
                name={`${item}.address`}
                value={startAddress + index}
                parse={(value) => Number(value, 10)}
                component="input"
                type="number"
                className="form-control w-100"
                disabled={!isOperator}
              />
            </div>
            <div className="flex-1">
              <Field
                name={`${item}.productId`}
                component={Select}
                className="form-control w-100"
                type="select"
                products={products}
                parse={(value) => Number(value)}
              >
                <option value="">{'Select Product'}</option>
                {createProductList(index)}
              </Field>
            </div>
            <button
              className="btn btn-secondary ml-2"
              style={{ display: 'inline-block' }}
              type="button"
              onClick={() => fields.remove(index)}
            >
              <i className="icon icon-cross" />
            </button>
          </div>
        );
      })}
      <div className="wash-counter-register-buttons-container">
        <button
          id="add-address-button"
          className="btn btn-secondary"
          data-testid="add-address-button"
          type="button"
          onClick={() =>
            addAddressField(fields, startAddress, products, !(products && products[0]) || undefined)
          }
        >
          {intl.formatMessage({ id: 'addAddress' })}
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddressFields;
