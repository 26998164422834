import { Big } from 'big.js';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import WashCount, {
  FleetPayAsYouGoDiscount,
  PayAsYouGoCount,
  PayAsYouGoDiscount,
  Refund,
} from './models';

const from = sessionStorage.getItem('washCountsFrom');
const until = sessionStorage.getItem('washCountsUntil');
const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

export const initialState = {
  washCounts: [],
  hourlyWashCounts: {},
  combinedCounts: {
    summary: [],
    washes: [],
    items: [],
    giftCards: [],
    plans: [],
    rewashes: [],
    interiorProducts: [],
  },
  discounts: [],
  payAsYouGoCounts: [],
  payAsYouGoDiscounts: [],
  fleetPayAsYouGoCounts: [],
  fleetPayAsYouGoDiscounts: [],
  summaryChart: 'washCounts',
  fromDate: from ? fromDate : moment.tz(tz).startOf('day'),
  untilDate: until ? untilDate : moment.tz(tz).endOf('day'),
  topWashesFromDate: moment.tz(tz).startOf('day'),
  topWashesUntilDate: moment.tz(tz).endOf('day'),
  subscriptionDate: moment.tz(tz).endOf('day'),
  adjustments: {},
  uniqueWashes: 0,
  showingCoreCountsDate: {},
  showingCoreDiscountsDate: {},
  topPerformingSingleWashes: {},
  topPerformingSubscriptionWashes: [],
  topPerformingWashesFetched: 0,
  refunds: [],
};

const calculateUniqueWashes = (state, singleWashes, rewashes) => {
  let washes = 0;

  if (singleWashes) {
    washes =
      washes +
      _.sumBy(
        singleWashes.filter(
          (wash) => !wash.product.includes('Club') && !wash.product.includes('Default')
        ),
        'count'
      );
  }

  if (rewashes) {
    washes = washes - _.sumBy(rewashes, 'count');
  }

  if (state?.payAsYouGoCounts.length) {
    washes = washes - _.sumBy(state.payAsYouGoCounts, 'count');
  }

  return washes;
};

const sumPaygCounts = (initialValues) => {
  const newValues = _.mapValues(_.groupBy(initialValues, 'name'), (arrayOfArrays) => {
    if (arrayOfArrays.length === 1) {
      return arrayOfArrays[0];
    }
    return arrayOfArrays.reduce((acc, item) => {
      acc.count = _.sumBy([item.count, acc.count]);
      acc.tax = Big(item.tax).plus(acc.tax).toFixed(2);
      acc.total = Big(item.total).plus(acc.total).toFixed(2);
      acc.amount = Big(item.amount).plus(acc.amount).toFixed(2);

      return acc;
    });
  });

  return _.values(newValues);
};

const sumPaygDiscounts = (initialValues) => {
  const newValues = _.mapValues(_.groupBy(initialValues, 'operationName'), (arrayOfArrays) => {
    if (arrayOfArrays.length === 1) {
      return arrayOfArrays[0];
    }
    return arrayOfArrays.reduce((acc, item) => {
      acc.count = _.sumBy([item.count, acc.count]);
      acc.tax = Big(item.tax).plus(acc.tax).toFixed(2);
      acc.total = Big(item.total).plus(acc.total).toFixed(2);
      acc.totalDiscount = Big(item.totalDiscount).plus(acc.totalDiscount).toFixed(2);

      return acc;
    });
  });

  return _.values(newValues);
};

const sumRefundCounts = (initialValues) => {
  let newValues = _.mapValues(initialValues, (values) => {
    if (values.length === 1) {
      return values[0];
    }
    return values.reduce((acc, item) => {
      acc.count = _.sumBy([item.count, acc.count]);
      acc.total = Big(item.total).plus(acc.total).toFixed(2);
      return acc;
    });
  });

  return _.values(newValues);
};

const getPayAsYouGoCounts = (payAsYouGoCounts, query) => {
  if (!query?.additionalSiteIds.length) {
    return _.sortBy(
      payAsYouGoCounts[query.siteId].map((count) => new PayAsYouGoCount(count)),
      'orderNumber'
    );
  }

  const aggregateValues = sumPaygCounts(payAsYouGoCounts.aggregate);

  return _.sortBy(
    aggregateValues.map((count) => new PayAsYouGoCount(count)),
    'orderNumber'
  );
};

const getPayAsYouGoDiscounts = (discounts = []) => {
  const aggregatedDiscounts = sumPaygDiscounts(discounts);
  return _.sortBy(
    aggregatedDiscounts.map((count) => new PayAsYouGoDiscount(count)),
    'orderNumber'
  );
};

const getFleetPayAsYouGoDiscounts = (discounts = []) => {
  const aggregatedDiscounts = sumPaygDiscounts(discounts);
  return _.sortBy(
    aggregatedDiscounts.map((discount) => new FleetPayAsYouGoDiscount(discount)),
    'orderNumber'
  );
};

const getCombinedCounts = (data) => {
  const categories = _.groupBy(data, 'category');
  const interiorProductIds = [];
  _.forEach(data, (obj) => {
    if (obj?.productCategory && obj?.productCategory.length) {
      _.forEach(obj.productCategory, (category) => {
        if (category.name === 'Interior') {
          interiorProductIds.push(obj.id);
          obj.productCategory = category.name;
        }
      });
    }
  });
  const washes = _.orderBy(categories.washes || [], 'orderNumber');

  return {
    items: categories.items || [],
    washes,
    giftCards: categories.giftCards || [],
    plans: categories.plans || [],
    rewashes: categories.rewashes || [],
    summary: categories.summary || [],
    interiorProducts: interiorProductIds || [],
  };
};

const getWashCounts = (data) => {
  const categories = _.groupBy(data, 'category');
  if (!categories || !categories.washes) {
    return [];
  }
  return _.sortBy(
    categories.washes.map((i) => {
      return new WashCount({
        product: i.name,
        count: i.runCount,
        color: i.color,
        orderNumber: i.orderNumber,
        category: i.category,
        washcountCategory: i.washcountCategory,
      });
    }),
    'orderNumber'
  );
};

const sumRevenueCounts = (acc, item) => {
  if (item?.items) {
    acc?.items.push(...item?.items);
  }
  if (item?.tax) {
    acc.tax = Big(acc.tax).plus(item.tax).toFixed(2);
  }
  if (item?.amount) {
    acc.amount = Big(acc.amount).plus(item.amount).toFixed(2);
  }
  if (item?.count) {
    acc.count = _.sumBy([acc.count, item.count]);
  }
  if (item?.runCount) {
    acc.runCount = _.sumBy([acc.runCount, item.runCount]);
  }
  if (item?.priceWithoutTax) {
    acc.priceWithoutTax = Big(acc.priceWithoutTax).plus(item.priceWithoutTax).toFixed(2);
  }
  if (item?.total) {
    acc.total = Big(acc.total).plus(item.total).toFixed(2);
  }

  return acc;
};

const reduceCategory = (category) => {
  return category.reduce((acc, item) => {
    return sumRevenueCounts(acc, item);
  });
};

const calculateAggregateValues = (response) => {
  let combined = {};
  const aggregateGroups = getCombinedCounts(response);
  for (const group in aggregateGroups) {
    let aggregateGroupCounts = [];
    const groupedCategoryByName = _.groupBy(aggregateGroups[group], 'name');
    for (const category in groupedCategoryByName) {
      aggregateGroupCounts.push(reduceCategory(groupedCategoryByName[category]));
    }

    _.assign(combined, {
      [group]: aggregateGroupCounts,
    });
  }

  return combined;
};

const fetchCoreCountsSuccess = (state, response, meta) => {
  let combinedCounts = {};
  if (response?.aggregate) {
    combinedCounts = calculateAggregateValues(response.aggregate);
  } else {
    combinedCounts = getCombinedCounts(response[meta.query.siteId]);
  }

  const washCounts = getWashCounts(combinedCounts.washes);
  const showingCoreCountsDate = meta.query;
  const uniqueWashes = calculateUniqueWashes(state, washCounts, combinedCounts?.rewashes);

  return {
    ...state,
    washCounts,
    combinedCounts,
    showingCoreCountsDate,
    uniqueWashes,
  };
};

const fetchCorePayAsYouGoCountsSuccess = (state, response, meta) => {
  const payAsYouGoCounts = getPayAsYouGoCounts(response, meta.query);
  const showingCoreCountsDate = meta.query;

  return {
    ...state,
    payAsYouGoCounts,
    showingCoreCountsDate,
  };
};

const fetchCoreFleetPayAsYouGoCountsSuccess = (state, response, meta) => {
  const fleetPayAsYouGoCounts = getPayAsYouGoCounts(response, meta.query);
  const showingCoreCountsDate = meta.query;

  return {
    ...state,
    fleetPayAsYouGoCounts,
    showingCoreCountsDate,
  };
};

const sumDiscountCounts = (initialDiscounts) => {
  const groupedDiscounts = _.groupBy(initialDiscounts, 'name');
  const usedNames = [];
  const concatValues = [];

  _.map(initialDiscounts, (discount) => {
    if (groupedDiscounts[discount.name]?.length && !usedNames.includes(discount.name)) {
      let itemArr = [];
      _.forEach(groupedDiscounts[discount.name], (item) => {
        itemArr.push(...item.promotionItems);
      });
      concatValues.push({ name: discount.name, promotionItems: itemArr });
      usedNames.push(discount.name);
    }
  });

  return concatValues;
};

const fetchCoreDiscountsSuccess = (state, discounts, meta) => {
  const showingCoreDiscountsDate = meta.query;
  const configuredDiscounts = discounts?.aggregate
    ? sumDiscountCounts(discounts.aggregate)
    : discounts[meta.query.siteId];

  _.forEach(configuredDiscounts, (discount) => {
    _.forEach(discount.promotionItems, (item) => {
      if (item?.productCategories && item?.productCategories.length) {
        _.forEach(item.productCategories, (category) => {
          if (category.name === 'Interior') {
            discount.productCategory = category.name;
          } else {
            discount.productCategory = 'Exterior';
          }
        });
      }
    });
  });

  return {
    ...state,
    discounts: configuredDiscounts,
    showingCoreDiscountsDate,
  };
};

const fetchCorePayAsYouGoDiscountsSuccess = (state, response, meta) => {
  const payAsYouGoDiscounts = response?.aggregate
    ? getPayAsYouGoDiscounts(response?.aggregate)
    : _.sortBy(
        response[meta.query.siteId].map((discount) => new PayAsYouGoDiscount(discount)),
        'orderNumber'
      );

  return {
    ...state,
    payAsYouGoDiscounts,
  };
};

const fetchCoreRefundsSuccess = (state, response, meta) => {
  const refunds = response?.aggregate
    ? sumRefundCounts(_.groupBy(response.aggregate, 'category'))
    : response[meta.query.siteId];

  return { ...state, refunds: _.map(refunds, (refund) => new Refund(refund)) };
};

const fetchCoreFleetPayAsYouGoDiscountsSuccess = (state, response, meta) => {
  const fleetDiscounts = response?.aggregate
    ? getFleetPayAsYouGoDiscounts(response?.aggregate)
    : _.sortBy(
        response[meta.query.siteId].map((discount) => new FleetPayAsYouGoDiscount(discount)),
        'orderNumber'
      );

  return {
    ...state,
    fleetPayAsYouGoDiscounts: fleetDiscounts,
  };
};

const fetchTopPerformingSingleWashes = (state, wash) => {
  const topPerformingSingleWashes = state.topPerformingSingleWashes;

  topPerformingSingleWashes[wash.siteId] = {
    ...wash,
    loading: false,
  };

  return {
    ...state,
    topPerformingSingleWashes,
    topPerformingWashesFetched: state.topPerformingWashesFetched + 1,
  };
};

const fetchTopPerformingSubscriptionWashes = (state, washes) => {
  return {
    ...state,
    topPerformingSubscriptionWashes: washes.results,
  };
};

const showWashCountChart = (state, chart) => {
  return {
    ...state,
    summaryChart: chart,
  };
};

const setFromDate = (state, date) => {
  window.sessionStorage.setItem('washCountsFrom', date.format('YYYY-MM-DD'));
  return {
    ...state,
    fromDate: date,
  };
};

const setUntilDate = (state, date) => {
  window.sessionStorage.setItem('washCountsUntil', date.format('YYYY-MM-DD'));
  return {
    ...state,
    untilDate: date,
  };
};

const setTopWashesFromDate = (state, date) => {
  return {
    ...state,
    topWashesFromDate: date,
  };
};

const setTopWashesUntilDate = (state, date) => {
  return {
    ...state,
    topWashesUntilDate: date,
  };
};

const setSubscriptionDate = (state, date) => {
  return {
    ...state,
    subscriptionDate: date,
  };
};

const setTopSingleWashesLoadersTrue = (state) => {
  const topPerformingSingleWashes = {};

  _.forEach(state.topPerformingSingleWashes, (wash) => {
    topPerformingSingleWashes[wash.siteId] = {
      ...wash,
      loading: true,
    };
  });

  return {
    ...state,
    topPerformingSingleWashes,
  };
};

const fetchAdjustmentsSuccess = (state, response) => {
  const adjustments = {
    adjustments: response.results,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
  return {
    ...state,
    adjustments: {
      ...state.adjustments,
      ...adjustments,
    },
  };
};

const fetchAdjustmentNotes = (state, response) => {
  const notes = response.results ? response.results.notes : '';
  return {
    ...state,
    adjustments: { ...state.adjustments, notes },
  };
};

const fetchHourlyWashCountsSuccess = (state, response) => {
  const hourlyWashCounts = _.omit(response, 'responseStatus');

  return { ...state, hourlyWashCounts };
};

export const washCounts = (state = initialState, action) => {
  const { response, meta, date, chart } = action;
  const returnedValue = response?.results ? response.results : response;
  switch (action.type) {
    case 'FETCH_CORE_COUNTS_SUCCESS':
      return fetchCoreCountsSuccess(state, returnedValue, meta);
    case 'FETCH_CORE_PAY_AS_YOU_GO_COUNTS_SUCCESS':
      return fetchCorePayAsYouGoCountsSuccess(state, returnedValue, meta);
    case 'FETCH_CORE_FLEET_PAY_AS_YOU_GO_COUNTS_SUCCESS':
      return fetchCoreFleetPayAsYouGoCountsSuccess(state, returnedValue, meta);
    case 'FETCH_CORE_FLEET_PAY_AS_YOU_GO_DISCOUNTS_SUCCESS':
      return fetchCoreFleetPayAsYouGoDiscountsSuccess(state, returnedValue, meta);
    case 'FETCH_CORE_DISCOUNTS_SUCCESS':
      return fetchCoreDiscountsSuccess(state, returnedValue, meta);
    case 'FETCH_CORE_PAY_AS_YOU_GO_DISCOUNTS_SUCCESS':
      return fetchCorePayAsYouGoDiscountsSuccess(state, returnedValue, meta);
    case 'FETCH_ADJUSTMENTS_SUCCESS':
      return fetchAdjustmentsSuccess(state, returnedValue);
    case 'FETCH_ADJUSTMENT_NOTES_SUCCESS':
      return fetchAdjustmentNotes(state, returnedValue);
    case 'FETCH_TOP_PERFORMING_SINGLE_WASHES_SUCCESS':
      return fetchTopPerformingSingleWashes(state, returnedValue);
    case 'FETCH_TOP_PERFORMING_SUBSCRIPTION_WASHES_SUCCESS':
      return fetchTopPerformingSubscriptionWashes(state, returnedValue);
    case 'FETCH_HOURLY_WASH_COUNTS_SUCCESS':
      return fetchHourlyWashCountsSuccess(state, returnedValue);
    case 'FETCH_CORE_REFUNDS_SUCCESS':
      return fetchCoreRefundsSuccess(state, returnedValue, meta);
    case 'SHOW_WASH_COUNT_CHART':
      return showWashCountChart(state, chart);
    case 'SET_WASH_COUNT_FROM_DATE':
      return setFromDate(state, date);
    case 'SET_WASH_COUNT_UNTIL_DATE':
      return setUntilDate(state, date);
    case 'SET_TOP_WASHES_FROM_DATE':
      return setTopWashesFromDate(state, date);
    case 'SET_TOP_WASHES_UNTIL_DATE':
      return setTopWashesUntilDate(state, date);
    case 'SET_SUBSCRIPTION_DATE':
      return setSubscriptionDate(state, date);
    case 'SET_TOP_SINGLE_WASHES_LOADERS_TRUE':
      return setTopSingleWashesLoadersTrue(state);
    default:
      return state;
  }
};

export const selectWashCounts = (state) => {
  return state.washCounts.washCounts;
};

export const selectCombinedCounts = (state) => {
  return state.washCounts.combinedCounts;
};

export const selectCombinedCountsWashes = (state) => {
  return _.get(state, 'washCounts.combinedCounts.washes', []);
};

export const selectCombinedCountsRewashes = (state) => {
  return _.get(state, 'washCounts.combinedCounts.rewashes', []);
};

export const selectProductPlans = (state) => {
  return _.get(state, 'washCounts.combinedCounts.plans', []);
};

export const selectProductSummary = (state) => {
  return _.get(state, 'washCounts.combinedCounts.summary', []);
};

export const selectPayAsYouGoCounts = (state) => {
  return state.washCounts.payAsYouGoCounts;
};

export const selectFleetPayAsYouGoCounts = (state) => {
  return state.washCounts.fleetPayAsYouGoCounts;
};

export const selectSummaryChart = (state) => {
  return state.washCounts.summaryChart;
};

export const selectFromDate = (state) => {
  return state.washCounts.fromDate;
};

export const selectUntilDate = (state) => {
  return state.washCounts.untilDate;
};

export const selectTopWashesFromDate = (state) => {
  return state.washCounts.topWashesFromDate;
};

export const selectTopWashesUntilDate = (state) => {
  return state.washCounts.topWashesUntilDate;
};

export const selectSubscriptionDate = (state) => {
  return state.washCounts.subscriptionDate;
};

export const selectDiscounts = (state) => {
  return state.washCounts.discounts;
};

export const selectPayAsYouGoDiscounts = (state) => {
  return state.washCounts.payAsYouGoDiscounts;
};

export const selectAdjustments = (state) => {
  return state.washCounts.adjustments.adjustments;
};

export const selectAdjustmentsPage = (state) => {
  return state.washCounts.adjustments.page;
};

export const selectAdjustmentsTotal = (state) => {
  return state.washCounts.adjustments.total;
};

export const selectAdjustmentsPageSize = (state) => {
  return state.washCounts.adjustments.pageSize;
};

export const selectAdjustmentsPaginationInfo = (state) => {
  return {
    total: selectAdjustmentsTotal(state),
    page: selectAdjustmentsPage(state),
    pageSize: selectAdjustmentsPageSize(state),
  };
};

export const selectShowingCoreCountsDate = (state) => {
  return state.washCounts.showingCoreCountsDate;
};

export const selectShowingCoreDiscountsDate = (state) => {
  return state.washCounts.showingCoreDiscountsDate;
};

export const selectAdjustmentNotes = (state) => {
  return state.washCounts.adjustments.notes;
};

export const selectTopPerformingSingleWashes = (state) => {
  return state.washCounts.topPerformingSingleWashes;
};

export const selectTopPerformingSubscriptionWashes = (state) => {
  return state.washCounts.topPerformingSubscriptionWashes;
};

export const selectTopPerformingWashesFetched = (state) => {
  return state.washCounts.topPerformingWashesFetched;
};

export const selectHourlyWashCounts = (state) => {
  return state.washCounts.hourlyWashCounts;
};

export const selectRefunds = (state) => {
  return state.washCounts.refunds;
};

export const selectFleetPayAsYouGoDiscounts = (state) => {
  return state.washCounts.fleetPayAsYouGoDiscounts;
};

export const selectUniqueWashes = (state) => {
  return state.washCounts.uniqueWashes;
};
