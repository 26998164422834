import React, { useEffect, useState } from 'react';
import Report from '../../analytics/components/ReportFrame';
import { models } from 'powerbi-client';
import { Report as ReportInterface } from 'powerbi-client';
import Loader from '../../../core/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectPowerBiConfig } from '../../analytics/reducer';
import { GET_REPORT, fetchReport } from '../../analytics/actions';
import { SAFETY_INTERVAL } from '../../analytics/sagas';
import { selectSiteExternalId } from '../../settings/reducer';
import { selectMultiSiteSelectionExternalIds } from '../../site-selection/reducer';
import { setWashCountsFilter } from '../../analytics/helpers';
import { getApiStatus } from '../../../core/utils';

interface ReportConfig {
  embedToken: string;
  embedUrl: string;
  reportId: string;
}

export const WashCountsAnalytics = (): JSX.Element => {
  let report: ReportInterface | null = null;
  const [pbiReport, setPbr] = useState<ReportInterface | null>(null);
  const currentSiteExternalId = useSelector(selectSiteExternalId);
  const multiSiteExtIds = useSelector(selectMultiSiteSelectionExternalIds);
  const reportConfig: ReportConfig | null = useSelector((state) =>
    selectPowerBiConfig(state, 'Wash Counts Tab')
  );
  const loader = useSelector((state) => getApiStatus(state, GET_REPORT));
  const currentPage = 'Wash Counts Tab';
  const dispatch = useDispatch();
  const mobileView = window.innerWidth <= 567;
  const hostname = window.location.hostname;
  const isWashClub =
    hostname === 'portal.wash.club' ||
    hostname === 'portal.staging-wash.club' ||
    hostname === 'portal.dev-wash.club';

  useEffect(() => {
    dispatch(fetchReport(SAFETY_INTERVAL, 'Wash Counts Tab', isWashClub));
  }, []);

  useEffect(() => {
    if (!loader) {
      handleMultiSiteFilter();
    }
  }, [loader, currentSiteExternalId, pbiReport]);

  const handleMultiSiteFilter = () => {
    const conditions = multiSiteExtIds.map((id) => {
      return {
        operator: 'Is',
        value: id.toString(),
      };
    });
    conditions.push({
      operator: 'Is',
      value: currentSiteExternalId,
    });

    if (pbiReport) {
      pbiReport.removeFilters();
      pbiReport
        .setFilters([setWashCountsFilter(conditions)])
        .catch((error) => console.error('ERROR: ', error));
    }
  };

  const handleReport = (reportComponent: ReportInterface) => {
    report = reportComponent;
    setPbr(report);
    if (report && report.iframe && report.iframe.contentWindow) {
      handleMultiSiteFilter();
    }

    if (report && mobileView) {
      report.updateSettings({
        layoutType: models.LayoutType.MobileLandscape,
      });
    }
  };

  const getReportComponent = () => {
    return !loader ? (
      <Report
        accessToken={reportConfig?.embedToken}
        embedUrl={reportConfig?.embedUrl}
        embedId={reportConfig?.reportId}
        pageName={currentPage}
        onLoad={handleReport}
        layoutType={models.LayoutType.MobileLandscape}
      />
    ) : (
      <div className="powerbi-loading">
        <Loader />
      </div>
    );
  };

  return <div className="page-main-content powerbi-container">{getReportComponent()}</div>;
};
