import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Input, requiredInput } from '../../../core/formValidation';
import AddressFields from './AddressFields';
import ManualAddressFields from './ManualAddressFields';
import ProgramAddressFields from './ProgramAddressFields';
import { Button, Collapse } from 'reactstrap';

const validateNewProducts = (values) => {
  const errors = {};
  if (!values.addressFields) {
    return errors;
  }
  const addressFieldErrors = [];
  values.addressFields.forEach((a, i) => {
    const addressErrors = {};
    if (!a || !a.name) {
      addressErrors.name = 'Required';
      addressFieldErrors[i] = addressErrors;
    }
  });
  if (addressFieldErrors.length) {
    errors.addressFields = addressFieldErrors;
  }
  return errors;
};

const WashCounterForm = ({
  startAddress,
  addressFields,
  products,
  operations,
  change,
  handleSubmit,
}) => {
  // context
  const intl = useIntl();
  // state
  const [toggle, setToggle] = useState({
    additionalRegisters: false,
    washRegisters: false,
  });

  const findUsableProducts = () => {
    // find products that are not in unique products
    const productsNotInUniqueProducts = products.filter(
      (p) => !uniqueProducts.some((up) => up.id === p.id)
    );
    return productsNotInUniqueProducts;
  };

  // make products unique using state
  const [uniqueProducts, setUniqueProducts] = useState([]);
  // make usable products unique using state
  const [usableProducts, setUsableProducts] = useState([]);
  // on mount, set unique products from address fields
  useEffect(() => {
    if (addressFields && addressFields.length) {
      setUniqueProducts(addressFields.map((a) => ({ id: a.productId, name: a.name })));
    }
  }, [addressFields]);

  useEffect(() => {
    if (uniqueProducts.length) {
      setUsableProducts(findUsableProducts());
    }
  }, [uniqueProducts]);

  const handleToggle = (value) => {
    setToggle({ ...toggle, [value]: !toggle[value] });
  };

  const handleProductChange = (event) => {
    event.preventDefault();
    const field = event.target.name.replace('.productId', '');
    const { id } = products.find((p) => p.id === Number(event.target.value));
    change(`${field}.productId`, id);
  };

  const handleProductChangeWithName = (field) => {
    if (field.id != null && !uniqueProducts.some((up) => up.id === field.id)) {
      setUniqueProducts([...uniqueProducts, field]);
      // update usable products using the new unique products
      setUsableProducts(findUsableProducts());
    }
  };

  const handleStartAddressChange = ({ target }) => {
    addressFields.forEach((a, i) => {
      const newAddress = a.index + Number(target.value || 0, 10);
      change(`addressFields[${i}].address`, newAddress);
    });
  };

  return (
    <form data-testid="wash-counter-form" onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'host' })}</label>
          <Field
            type="text"
            className="form-control"
            name="host"
            id="host"
            component={requiredInput}
          />
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'mcpPort' })}</label>
          <Field
            type="number"
            className="form-control"
            name="port"
            id="port"
            component={requiredInput}
            parse={(value) => (value ? Number(value, 10) : null)}
          />
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'startingAddress' })}</label>
          <Field
            type="number"
            className="form-control"
            name="startAddress"
            id="startAddress"
            component={requiredInput}
            onChangeCapture={(event) => handleStartAddressChange(event, change, addressFields)}
            parse={(value) => (value ? Number(value, 10) : null)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'carCountAddress' })}</label>
          <Field
            type="number"
            className="form-control"
            name="totalCarCountAddress"
            id="totalCarCountAddress"
            component={requiredInput}
            parse={(value) => (value ? Number(value, 10) : null)}
          />
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'lastHourAddress' })}</label>
          <Field
            type="number"
            className="form-control"
            name="lastHourAddress"
            id="lastHourAddress"
            component={requiredInput}
            parse={(value) => (value ? Number(value, 10) : null)}
          />
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'currentHourAddress' })}</label>
          <Field
            type="number"
            className="form-control"
            name="currentHourAddress"
            id="currentHourAddress"
            component={requiredInput}
            parse={(value) => (value ? Number(value, 10) : null)}
          />
        </div>
      </div>
      <div onClick={() => handleToggle('additionalRegisters')} className="form-title collapsible">
        <span>{intl.formatMessage({ id: 'additionalRegisters' })}</span>
        <div style={{ border: '0px' }}>
          <i className="icon icon-chevron-down" />
        </div>
      </div>
      <Collapse isOpen={toggle.additionalRegisters}>
        <div className="form-row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'lcPort' })}</label>
            <Field
              type="number"
              className="form-control"
              name="lcPort"
              id="lcPort"
              component={Input}
              parse={(value) => (value ? Number(value, 10) : null)}
            />
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'nmPort' })}</label>
            <Field
              type="number"
              className="form-control"
              name="nmPort"
              id="nmPort"
              component={Input}
              parse={(value) => (value ? Number(value, 10) : null)}
            />
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'vcPort' })}</label>
            <Field
              type="number"
              className="form-control"
              name="vcPort"
              id="vcPort"
              component={Input}
              parse={(value) => (value ? Number(value, 10) : null)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'rocPort' })}</label>
            <Field
              type="number"
              className="form-control"
              name="rocPort"
              id="rocPort"
              component={Input}
              parse={(value) => (value ? Number(value, 10) : null)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group mt-3 mb-0">
            <label>{intl.formatMessage({ id: 'manualEntries' })}</label>
          </div>
        </div>
        <FieldArray
          name="manualAddresses"
          component={ManualAddressFields}
          products={products}
          intl={intl}
          handleProductChange={(event) => handleProductChange(event, change, products)}
        />
        <div className="form-row">
          <div className="form-group mt-3 mb-0">
            <label>{intl.formatMessage({ id: 'manualEntriesPrograms' })}</label>
          </div>
        </div>
        <FieldArray
          name="manualEntriesPrograms"
          component={ProgramAddressFields}
          intl={intl}
          handleProductChange={(event) => handleProductChange(event, change, products)}
        />
      </Collapse>
      <div onClick={() => handleToggle('washRegisters')} className="form-title collapsible">
        <span>{intl.formatMessage({ id: 'washRegisters' })}</span>
        <div style={{ border: '0px' }}>
          <i className="icon icon-chevron-down" />
        </div>
      </div>
      <Collapse isOpen={toggle.washRegisters}>
        <FieldArray
          name="addressFields"
          component={AddressFields}
          startAddress={startAddress}
          remainingProducts={usableProducts}
          products={products}
          operations={operations}
          handleProductChange={(event) => handleProductChangeWithName(event, change, products)}
        />
      </Collapse>
    </form>
  );
};

export const WashControllerConfigForm = reduxForm({
  form: 'washCounter',
  validate: validateNewProducts,
})(WashCounterForm);
