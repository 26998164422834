import { formatAmount } from '../../utils/money-util';

export default class WashCount {
  constructor(props = {}) {
    this.productId = props.productId;
    this.product = props.product;
    this.count = props.count;
    this.color = props.color;
    this.orderNumber = props.orderNumber;
    this.category = props.category;
    this.washcountCategory = props.washcountCategory;
  }
}

export const ServiceType = {
  Automatic: 0,
  SelfService: 1,
  VacuumCleaner: 2,
  DogWash: 3,
};

export const WashCategory = {
  Exterior: 'exterior',
  Interior: 'interior',
  Both: 'both',
};

export class PayAsYouGoCount {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.count = props.count;
    this.runCount = props.runCount;
    this.price = props.price;
    this.amount = props.amount;
    this.tax = props.tax;
    this.total = props.total;
    this.productCategory = props.productCategory;
    this.currency = props.currency || 'usd';
    this.orderNumber = props.orderNumber || Number.MAX_SAFE_INTEGER;
    this.isOldPrice = props.isOldPrice;
  }

  formatToTableData() {
    return {
      _id: this.id,
      _isOldPrice: this.isOldPrice,
      name: this.name,
      count: this.count,
      price: formatAmount(this.price, this.currency),
      amount: formatAmount(this.amount, this.currency),
      tax: formatAmount(this.tax, this.currency),
      total: formatAmount(this.total, this.currency),
    };
  }
}

export class PayAsYouGoDiscount {
  constructor(props = {}) {
    this.operationId = props.operationId;
    this.operationName = props.operationName;
    this.count = props.count;
    this.totalDiscount = props.totalDiscount;
    this.tax = props.tax;
    this.total = props.total;
    this.currency = props.currency || 'usd';
    this.category = props.category;
    this.orderNumber = props.orderNumber;
  }

  formatToTableData() {
    return {
      _id: this.operationId,
      operationName: this.operationName,
      count: this.count,
      totalDiscount: formatAmount(this.totalDiscount, this.currency),
    };
  }
}

export class FleetPayAsYouGoDiscount {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.operationName = props.operationName;
    this.count = props.count;
    this.total = props.total;
    this.currency = props.currency || 'usd';
    this.category = props.category;
    this.tierLevel = props.tierLevel;
    this.orderNumber = props.orderNumber;
  }

  formatToTableData() {
    return {
      _id: this.name,
      tierLevel: `Tier ${this.tierLevel}`,
      count: this.count,
      total: formatAmount(this.total, this.currency),
    };
  }
}

export class Refund {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.count = props.count;
    this.tax = props.tax;
    this.total = props.total;
    this.currency = props.currency || 'usd';
    this.category = props.category;
  }

  formatToTableData() {
    return {
      _id: this.id,
      name: this.name,
      amount: null,
      tax: formatAmount(this.tax, this.currency),
      total: formatAmount(this.total, this.currency),
    };
  }
}
